import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react"
import { Helmet } from "react-helmet";
import { FooterQueryDataType } from "./types";


export default function Footer() {
  const { storePages, presentationPage, homePage, storeSocialMedia } = useStaticQuery<FooterQueryDataType>(query);
  const {
    store_facebook,
    store_instagram,
    store_linkedin,
    store_snapchat,
    store_tiktok,
    store_twitter,
    store_youtube
  } = storeSocialMedia.store_primary_settings.store_social_media;

  return (
    <footer className="bg-dark text-white">
      <div className="container py-4">
        <div className="row py-5">
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="text-uppercase mb-3">Services</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <Link className="footer-link" to={"/"}>
                  {homePage?.title || "Home"}
                </Link>
              </li>
              {
                storePages.edges.map(({ node: page }) => (
                  <li key={`footer-item-${page?.slug}`}>
                    <Link className="footer-link" to={`/${page?.slug || ""}/`}>
                      {page?.title || ""}
                    </Link>
                  </li>
                ))
              }
              <li>
                <Link className="footer-link" to={"/contact/"}>
                  Contact
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/cart/`}>
                  Panier
                </Link>
              </li>
              <li>
                <Link className="footer-link" to={`/cart/checkout/`}>
                  Paiement
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="text-uppercase mb-3">à propos</h6>
            <ul className="list-unstyled mb-0">
              {
                presentationPage && (
                  <li>
                    <Link className="footer-link" to={`/${presentationPage?.slug}/`}>
                      {presentationPage?.title || "About"}
                    </Link>
                  </li>
                )
              }
              <li>
                <Link className="footer-link" to={`/shop/`}>
                  Boutique
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h6 className="text-uppercase mb-3">réseaux sociaux</h6>
            <ul className="list-unstyled mb-0">
              {
                !!store_twitter && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_twitter}>Twitter</a></li>
                )
              }
              {
                !!store_facebook && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_facebook}>Facebook</a></li>
                )
              }
              {
                !!store_instagram && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_instagram}>Instagram</a></li>
                )
              }
              {
                !!store_snapchat && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_snapchat}>Snapchat</a></li>
                )
              }
              {
                !!store_tiktok && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_tiktok}>Tiktok</a></li>
                )
              }
              {
                !!store_linkedin && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_linkedin}>Linkedin</a></li>
                )
              }
              {
                !!store_youtube && (
                  <li><a target="_blank" rel="noopener" className="footer-link" href={store_youtube}>Youtube</a></li>
                )
              }
            </ul>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col text-center">
            <a
              href={"https://docs.google.com/forms/d/1vvGNy3qebQlFDKTvapFVlUgao7fN4m37ngJmz4jty2Y/edit#question=2055797873&field=1670110578"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              className={"text-muted"}
            >
              voulez-vous avoir un site ecommerce pour vendre vos produits? les pré-inscriptions sonts ouvertes
            </a>
          </div>
        </div>
        <div className="border-top pt-4">
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <p className="small text-muted mb-0">&copy; {(new Date()).getFullYear()} All rights reserved.</p>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <p className="small text-muted mb-0">
                Brought to you by{` `}
                <a className="text-white reset-anchor" href="https://www.zzenz.com">Zzenz</a>{` `}
                and made with love and Gatsby
              </p>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        />
      </Helmet>
    </footer>
  );
}

const query = graphql`
  query FooterPages {
    presentationPage: zzPage(page_type:{eq: "presentation"}){
      id
      slug
      title
    }
    homePage: zzPage(page_type:{eq: "home"}){
      id
      slug
      title
    }
    storePages: allZzPage(
      filter: {page_type: {nin: ["home", "presentation"]}, type: {eq: "page"}, status: {eq: "publish"}}
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    storeSocialMedia: zzStoreWebsiteDetails {
      id
      store_primary_settings {
        store_social_media {
          store_instagram
          store_facebook
          store_twitter
          store_tiktok
          store_linkedin
          store_youtube
          store_snapchat
        }
      }
    }
  }
`;