import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react"
import { HeaderQueryDataType } from "../footer/types";
import { GatsbyImage, getImage, IGatsbyImageData, } from "gatsby-plugin-image";
import { getNonEmptyCategories } from "../../helpers/data/categories";
import { useCart } from "../../hooks/useCart";

export default function Header() {
  const [cart] = useCart();
  const {
    storeSocialMedia: {
      localFile,
    },
    productCategories,
    products
  } = useStaticQuery<HeaderQueryDataType>(query);
  const numOfItems = cart.data?.lineItems?.length || 0;
  const logo = getImage(localFile);
  const usedCategories = getNonEmptyCategories(productCategories, products);

  return (
    <header className="header bg-white">
      <div className="container px-lg-3">
        <nav className="navbar navbar-expand-lg navbar-light py-3 px-lg-0">
          <Link className="navbar-brand p-0" to="/">
            <GatsbyImage
              image={logo as IGatsbyImageData}
              alt={"logo"}
            />
          </Link>
          <button className="navbar-toggler navbar-toggler-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link active" to="/">Accueil</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/shop/">Boutique</Link>
              </li>
              {
                usedCategories.length > 0 && (
                  <>
                    <a className="nav-link dropdown-toggle" id="pagesDropdown" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Catégories</a>
                    <ul className="dropdown-menu mt-3 shadow-sm" aria-labelledby="pagesDropdown">
                      {
                        usedCategories.map(({ node: category }) => (
                          <li key={`nav-category-${category.slug}-${category.term_id}`}>
                            <Link
                              to={`/category/${category.slug}/`}
                              className="dropdown-item border-0 transition-link"
                            >
                              {category.name}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </>
                )
              }
            </ul>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/contact/">Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cart/">
                  <svg
                    stroke="#aaa"
                    fill="#aaa"
                    strokeWidth="0"
                    viewBox="0 0 576 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-1"
                  >
                    <path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z" />
                  </svg>
                  <span>Panier</span>
                  <small className="text-gray fw-normal">
                    ({numOfItems})
                  </small>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cart/checkout/">Paiement</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

const query = graphql`
  query HeaderPages {
    storeSocialMedia: zzStoreWebsiteDetails {
      localFile {
        childImageSharp {
          gatsbyImageData(
            height: 50
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    productCategories: allWcProductsCategories {
      edges {
        node {
          term_id
          slug
          name
        }
      }
    }
    products: allWcProducts {
      edges {
        node {
          category_ids
        }
      }
    }
  }
`;