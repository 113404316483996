import { HeaderQueryDataType } from "../../../components/footer/types";

/**
 * Retrieve only used categories
 * 
 * @param categories list of store categories
 * @param products all store products
 * @returns used categories list
 */
export function getNonEmptyCategories(categories: HeaderQueryDataType["productCategories"], products: HeaderQueryDataType["products"]) {
  const usedProductCategories = new Set();
  // loop over products and get used categories
  products.edges.forEach(({ node: product }) => {
    if (product?.category_ids?.length > 0) {
      product.category_ids.forEach(id => usedProductCategories.add(id));
    }
  });
  // filter categories by selecting those who used to tag products
  const usedCategories = categories.edges.filter(({ node: category }) => {
    return usedProductCategories.has(category.term_id)
  });

  return usedCategories;
}