import React from "react"
import Footer from "../footer"
import Header from "../header"
import { LayoutPropsTypes } from "./types"

import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style-default.css";

export default function Layout({ className, id, style, children }: LayoutPropsTypes) {
  return (
    <div className={className} id={id} style={style}>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}
