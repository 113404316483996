import { useEffect, useReducer, useRef } from "react";
import { globalReducer } from "../../helpers/reducers";
import CartStorage from "../../helpers/storage";

const initState = {
  loading: true,
  data: {
    lineItems: [],
    products: null,
    shippingLines: []
  },
  errors: null,
  message: null,
}
/**
 * Retrieve cart details
 * @returns cart details as line Items and products details
 */
export function useCart() {
  const _isMounted = useRef(true);
  const [state, dispatch] = useReducer(globalReducer, initState);

  useEffect(() => {
    const loadInitialData = async () => {
      const cart = new CartStorage();
      const [lineItems, products, shippingLines] = await Promise.all([
        cart.getAllItems(),
        cart.getItemsDetails(),
        cart.getShippingLines(),
      ]);

      _isMounted && dispatch({
        type: "SET_FIELDS",
        payload: {
          lineItems: lineItems ?? [],
          products: products ?? {},
          shippingLines: shippingLines ?? [],
        }
      });
    }
    loadInitialData();

    return () => {
      _isMounted.current = false;
    }
  }, []);

  return [state, dispatch] as const;
}